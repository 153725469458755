











































































































import { ability } from '@/auth/abilities';
import TagInput from '@/components/TagInput.vue';
import { Component, Vue } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { capitalize } from 'vue-string-filter';
import { namespace } from 'vuex-class';
import { Executor } from '../api';
import ExecutorStatusIcon from '../components/ExecutorStatusIcon.vue';
import SaveButton from '../components/SaveButton.vue';
import { IToast } from '../store/toast.module';
import { formatDateTime } from '../utils';

const toast = namespace('toast');
const executor = namespace('executor');

@Component({
  components: { SaveButton, ExecutorStatusIcon, TagInput },
})
export default class ExecutorEdit extends Vue {
  /** Only show the UI once the data has loaded;
   * needed to wrap this into an object, by just
   * using a plain flag it wouldn’t work -- binding
   * issue? */
  state: { loaded: boolean } = { loaded: false };
  initialStatus: string | null = null;
  initialPaused: boolean | null = null;

  @executor.Getter
  executor!: Executor;

  @executor.Action
  private loadExecutor!: (id: string) => Promise<void>;

  @executor.Action
  private saveExecutor!: () => Promise<void>;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  async created(): Promise<void> {
    this.state.loaded = false;
    await this.loadExecutor(this.$route.params.id);
    this.initialStatus = this.executor.status;
    this.initialPaused = this.executor.paused;
    this.state.loaded = true;
  }

  async submit(): Promise<void> {
    await this.save();
    await this.$router.push({ name: 'executors' });
  }

  async save(): Promise<void> {
    await this.saveExecutor();
    this.saveButton.setPristine();
    this.setToast({ message: `Executor “${this.executor.description}” was saved.` });
  }

  get cannotEdit(): boolean {
    return '_id' in this.executor && !!this.executor._id && !ability.can('update', this.executor);
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.saveButton.delegateBeforeRouteLeave(to, from, next);
  }

  private get saveButton(): SaveButton {
    return this.$refs['save-button'] as SaveButton;
  }

  get initialStatusText(): string {
    if (!this.initialStatus) return '';
    const status = capitalize(this.initialStatus);
    return this.initialPaused ? `${status} (Paused)` : status;
  }

  get createdAt(): string {
    return formatDateTime(this.executor.createdAt)?.combined ?? this.executor.createdAt;
  }

  get lastSeenAt(): string | undefined {
    return this.executor.lastSeenAt
      ? formatDateTime(this.executor.lastSeenAt)?.combined || this.executor.lastSeenAt
      : undefined;
  }
}
