











import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownAZ } from '@fortawesome/free-solid-svg-icons';
import { Component, Prop, Vue } from 'vue-property-decorator';

library.add(faArrowDownAZ);

@Component
export default class SearchInput extends Vue {
  @Prop({ type: String, required: true }) readonly value!: string;
  @Prop({ type: Array, required: true }) readonly options!: { key: string; label: string }[];

  onUpdate(value: string): void {
    this.$emit('input', value);
  }
}
