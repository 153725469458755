import { IconDefinition, IconName } from '@fortawesome/fontawesome-svg-core';

/* _assets_originals/environment-knime.svg */
export const environmentKnime = makeIcon(
  16,
  16,
  'M8.899,1.4l6.957,12.036c0.187,0.322 0.187,0.72 0.001,1.043c-0.187,0.322 -0.531,0.521 -0.903,0.521l-13.915,0c-0.373,0 -0.717,-0.199 -0.903,-0.521c-0.186,-0.323 -0.186,-0.721 0,-1.043l6.958,-12.036c0.186,-0.323 0.53,-0.521 0.902,-0.521c0.372,-0 0.716,0.198 0.903,0.521Zm2.886,9.005c0.145,-0.083 0.234,-0.238 0.234,-0.405c0,-0.167 -0.089,-0.322 -0.234,-0.405l-5.086,-3.125c-0.145,-0.084 -0.324,-0.084 -0.469,-0.001c-0.145,0.084 -0.234,0.239 -0.234,0.406l0,6.25c0,0.167 0.089,0.322 0.234,0.406c0.145,0.083 0.324,0.083 0.469,-0.001l5.086,-3.125Z',
  'environment-knime'
);

/* _assets_originals/environment-shell.svg */
export const environmentShell = makeIcon(
  16,
  16,
  'M16,4.557l0,6.918c0,1.402 -1.139,2.541 -2.541,2.541l-10.918,-0c-1.402,-0 -2.541,-1.139 -2.541,-2.541l0,-6.918c0,-1.403 1.139,-2.541 2.541,-2.541l10.918,-0c1.402,-0 2.541,1.138 2.541,2.541Zm-13.478,1.388l2.385,2.055c-0,-0 -2.385,2.055 -2.385,2.055c-0.419,0.361 -0.466,0.995 -0.105,1.414c0.361,0.42 0.995,0.467 1.414,0.105l3.267,-2.814c0.221,-0.191 0.348,-0.468 0.348,-0.76c0,-0.292 -0.127,-0.569 -0.348,-0.76l-3.267,-2.814c-0.419,-0.362 -1.053,-0.315 -1.414,0.105c-0.361,0.419 -0.314,1.053 0.105,1.414Zm5.63,6.061l4.847,-0c0.554,-0 1.003,-0.449 1.003,-1.003c-0,-0.554 -0.449,-1.003 -1.003,-1.003l-4.847,0c-0.553,-0 -1.003,0.449 -1.003,1.003c0,0.554 0.45,1.003 1.003,1.003Z',
  'environment-shell'
);

/* _assets_originals/environment-nodejs.svg */
export const environmentNodeJs = makeIcon(
  16,
  16,
  [
    'M15.307,3.692l-6.614,-3.526c-0.42,-0.221 -0.973,-0.221 -1.393,0l-6.607,3.52c-0.426,0.228 -0.693,0.659 -0.693,1.114l0,6.388c0,0.461 0.267,0.886 0.693,1.114l1.734,0.923c0.84,0.381 1.14,0.381 1.52,0.381c1.246,0 1.96,-0.695 1.96,-1.901l-0,-6.591c-0,-0.099 -0.087,-0.179 -0.194,-0.179l-0.833,0c-0.113,0 -0.193,0.08 -0.193,0.179l-0,6.584c-0,0.542 -0.607,1.071 -1.587,0.622l-1.813,-0.966c-0.067,-0.031 -0.107,-0.099 -0.107,-0.166l0,-6.382c0,-0.074 0.04,-0.135 0.107,-0.172l6.606,-3.52c0.06,-0.037 0.14,-0.037 0.207,-0l6.607,3.52c0.066,0.037 0.106,0.098 0.106,0.166l0,6.388c0,0.067 -0.04,0.135 -0.1,0.166l-6.613,3.526c-0.06,0.031 -0.147,0.031 -0.207,0l-1.7,-0.929c-0.046,-0.031 -0.113,-0.037 -0.16,-0.013c-0.473,0.247 -0.56,0.277 -1,0.419c-0.106,0.031 -0.273,0.092 0.06,0.265l2.207,1.206c0.213,0.11 0.453,0.172 0.693,0.172c0.247,0 0.487,-0.062 0.7,-0.172l6.614,-3.526c0.426,-0.228 0.693,-0.653 0.693,-1.114l0,-6.382c0,-0.461 -0.267,-0.886 -0.693,-1.114Z',
    'M13.333,9.597c0,1.329 -1.2,2.092 -3.293,2.092c-2.893,0 -3.5,-1.224 -3.5,-2.252c0,-0.092 0.08,-0.172 0.187,-0.172l0.86,-0c0.093,-0 0.173,0.061 0.186,0.147c0.127,0.8 0.514,1.206 2.267,1.206c1.393,0 1.987,-0.289 1.987,-0.966c-0,-0.394 -0.167,-0.689 -2.334,-0.886c-1.813,-0.166 -2.933,-0.535 -2.933,-1.871c0,-1.23 1.127,-1.969 3.013,-1.969c2.12,0 3.167,0.683 3.3,2.136c0.007,0.055 -0.013,0.104 -0.046,0.135c-0.04,0.037 -0.087,0.061 -0.14,0.061l-0.86,0c-0.094,0 -0.167,-0.061 -0.187,-0.141c-0.207,-0.843 -0.713,-1.114 -2.067,-1.114c-1.52,0 -1.7,0.486 -1.7,0.855c0,0.444 0.207,0.573 2.267,0.819c2.027,0.252 2.993,0.603 2.993,1.92Z',
  ].join(' '),
  'environment-nodejs'
);

/* _assets_originals/environment-python.svg */
export const environmentPython = makeIcon(
  16,
  16,
  [
    'M0,9.5l0,-4c0,-0.828 0.672,-1.5 1.5,-1.5l5.5,0l-0,-1l-4,0l0,-1.5c0,-0.828 0.672,-1.5 1.5,-1.5l6,0c0.828,0 1.5,0.672 1.5,1.5l-0,4c-0,0.828 -0.672,1.5 -1.5,1.5l-5.5,0c-1.104,0 -2,0.896 -2,2l0,2l-1.5,0c-0.828,0 -1.5,-0.672 -1.5,-1.5Zm4.5,-8.75c-0.414,0 -0.75,0.336 -0.75,0.75c-0,0.414 0.336,0.75 0.75,0.75c0.414,0 0.75,-0.336 0.75,-0.75c0,-0.414 -0.336,-0.75 -0.75,-0.75Z',
    'M16,9.5c-0,0.828 -0.672,1.5 -1.5,1.5l-5.5,0l-0,1l4,0l-0,1.5c-0,0.828 -0.672,1.5 -1.5,1.5l-6,0c-0.828,0 -1.5,-0.672 -1.5,-1.5l0,-4c0,-0.828 0.672,-1.5 1.5,-1.5l5.5,0c1.104,0 2,-0.896 2,-2l-0,-2l1.5,0c0.828,0 1.5,0.672 1.5,1.5l-0,4Zm-4.5,4.75c0.414,0 0.75,-0.336 0.75,-0.75c-0,-0.414 -0.336,-0.75 -0.75,-0.75c-0.414,0 -0.75,0.336 -0.75,0.75c-0,0.414 0.336,0.75 0.75,0.75Z',
  ].join(' '),
  'environment-python'
);

/* _assets_originals/file-knime.svg */
export const fileKnwf = makeIcon(
  384,
  512,
  'M64,0l160,0l0,128c0,17.7 14.3,32 32,32l128,0l0,288c0,35.3 -28.7,64 -64,64l-256,0c-35.3,0 -64,-28.7 -64,-64l0,-384c0,-35.3 28.7,-64 64,-64Zm140.575,226.671c-2.595,-4.49 -7.388,-7.256 -12.575,-7.256c-5.187,0 -9.98,2.766 -12.575,7.256l-96.937,167.694c-2.598,4.494 -2.6,10.033 -0.006,14.529c2.595,4.496 7.391,7.266 12.581,7.266l193.874,-0c5.19,-0 9.986,-2.77 12.581,-7.266c2.594,-4.496 2.592,-10.035 -0.006,-14.529l-96.937,-167.694Zm40.216,125.473l-70.869,43.539c-2.019,1.167 -4.506,1.168 -6.526,0.003c-2.019,-1.165 -3.263,-3.319 -3.263,-5.651l-0,-87.078c-0,-2.331 1.244,-4.486 3.263,-5.651c2.02,-1.165 4.507,-1.164 6.526,0.003l70.869,43.539c2.017,1.166 3.259,3.319 3.259,5.648c0,2.33 -1.242,4.482 -3.259,5.648Zm11.209,-352.144l128,128l-128,0l-0,-128Z',
  'file-knwf'
);

/* _assets_originals/play-pause-circle.svg */
export const playPauseCircle = makeIcon(
  512,
  512,
  'M0,256C-0,115.563 115.563,0 256,0C396.437,-0 512,115.563 512,256C512,396.437 396.437,512 256,512C115.563,512 0,396.437 0,256ZM346.266,181.309C346.266,160.653 331.966,143.964 314.266,143.964C296.566,143.964 282.266,160.653 282.266,181.309L282.266,330.691C282.266,351.347 296.566,368.036 314.266,368.036C331.966,368.036 346.266,351.347 346.266,330.691L346.266,181.309ZM429.834,181.309C429.834,160.653 415.534,143.964 397.834,143.964C380.134,143.964 365.834,160.653 365.834,181.309L365.834,330.691C365.834,351.347 380.134,368.036 397.834,368.036C415.534,368.036 429.834,351.347 429.834,330.691L429.834,181.309ZM89.117,147.182C81.517,151.382 76.817,159.482 76.817,168.082L76.817,344.082C76.817,352.782 81.517,360.782 89.117,364.982C96.717,369.182 105.917,369.082 113.417,364.482L257.417,276.482C264.517,272.082 268.917,264.382 268.917,255.982C268.917,247.582 264.517,239.882 257.417,235.482L113.417,147.482C106.017,142.982 96.717,142.782 89.117,146.982L89.117,147.182Z',
  'play-pause-circle'
);

function makeIcon(width: number, height: number, path: string, iconName: string): IconDefinition {
  return {
    icon: [width, height, [], '', path],
    prefix: 'fas',
    iconName: iconName as IconName,
  };
}
