

































































































































import { library } from '@fortawesome/fontawesome-svg-core';
import { faBan, faCircleInfo, faPause, faPlay, faRocket } from '@fortawesome/free-solid-svg-icons';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as api from '../api';
import { Executor, ExecutorList, SortExecutors } from '../api';
import DateTime from '../components/DateTime.vue';
import EmptyListPlaceholder from '../components/EmptyListPlaceholder.vue';
import ExecutorStatusIcon from '../components/ExecutorStatusIcon.vue';
import SearchInput from '../components/SearchInput.vue';
import SortInput from '../components/SortInput.vue';
import { IToast } from '../store/toast.module';

library.add(faRocket, faBan, faCircleInfo, faPause, faPlay);

const toast = namespace('toast');

@Component({
  components: { ExecutorStatusIcon, EmptyListPlaceholder, DateTime, SearchInput, SortInput },
})
export default class ExecutorListComponent extends Vue {
  private pollInterval: number | undefined;
  executorList: ExecutorList | null = null;
  private q = '';
  cleanupRunning = false;
  sort: SortExecutors = '-lastSeenAt';

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  async mounted(): Promise<void> {
    await this.load();
    this.pollInterval = setInterval(() => void this.load(), 10000);
  }

  beforeDestroy(): void {
    clearInterval(this.pollInterval);
  }

  onSearchInput(q: string) {
    this.q = q;
    this.load();
  }

  onSortInput(sort: SortExecutors) {
    this.sort = sort;
    this.load();
  }

  private async load(): Promise<void> {
    this.executorList = await api.listExecutors({ q: this.q, sort: this.sort });
  }

  get sortedExecutors(): Executor[] | undefined {
    // Returns executors as given when not sorting by lastSeenAt
    if (!this.executorList?.executors || !['lastSeenAt', '-lastSeenAt'].includes(this.sort)) {
      return this.executorList?.executors;
    }

    // Override the initial sorting and re-sort by lastSeenAt (rounded by minute) and description
    const order = this.sort === 'lastSeenAt' ? 1 : -1;
    return this.executorList.executors.sort((a: Executor, b: Executor) => {
      const aMinutes = Math.floor(new Date(a.lastSeenAt || 0).getTime() / 60000);
      const bMinutes = Math.floor(new Date(b.lastSeenAt || 0).getTime() / 60000);
      return aMinutes === bMinutes ? a.description.localeCompare(b.description) : (aMinutes - bMinutes) * order;
    });
  }

  async remove(item: Executor): Promise<void> {
    const reallyDelete = await this.$bvModal.msgBoxConfirm(`Really delete executor “${item.description}”?`, {
      okVariant: 'danger',
      okTitle: 'Delete',
      cancelVariant: 'outline-dark',
      cancelTitle: 'Cancel',
    });
    if (reallyDelete) {
      await api.deleteExecutor(item._id);
      this.load();
      this.setToast({ message: `Executor “${item.description}” was deleted.` });
    }
  }

  async togglePause(item: Executor): Promise<void> {
    const action = item.paused ? 'resume' : 'pause';
    const reallyPause = await this.$bvModal.msgBoxConfirm(`Really ${action} executor “${item.description}”?`, {
      okVariant: 'dark',
      okTitle: action.charAt(0).toUpperCase() + action.slice(1),
      cancelVariant: 'outline-danger',
      cancelTitle: 'Cancel',
    });
    if (reallyPause) {
      await api.updateExecutor(item._id, { ...item, paused: !item.paused });
      this.load();
      this.setToast({ message: `Executor “${item.description}” was ${action}d.` });
    }
  }

  async cleanupExecutors(): Promise<void> {
    this.cleanupRunning = true;
    try {
      const cleanupResult = await api.cleanupExecutors();
      this.load();
      this.setToast({ message: `Cleanup removed ${cleanupResult.numExecutorsDeleted} executors.` });
    } finally {
      this.cleanupRunning = false;
    }
  }

  get sortOptions(): { key: SortExecutors; label: string }[] {
    return [
      { key: 'description', label: 'Description' },
      { key: 'lastSeenAt', label: 'Last Seen ↓' },
      { key: '-lastSeenAt', label: 'Last Seen ↑' },
      { key: '-runCount', label: '# Runs' },
    ];
  }
}
