






























































































































import { library } from '@fortawesome/fontawesome-svg-core';
import { faBan, faBroom, faClone, faLayerGroup, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { capitalize } from 'vue-string-filter';
import { namespace } from 'vuex-class';
import * as api from '../api';
import { Environment, EnvironmentList, Project, QueryEnvironmentType, SortEnvironments } from '../api';
import { ability } from '../auth/abilities';
import DateTime from '../components/DateTime.vue';
import EmptyListPlaceholder from '../components/EmptyListPlaceholder.vue';
import EnvironmentIcon from '../components/EnvironmentIcon.vue';
import RunStatus from '../components/RunStatus.vue';
import SearchInput from '../components/SearchInput.vue';
import SortInput from '../components/SortInput.vue';
import { IToast } from '../store/toast.module';

library.add(faPlus, faBan, faLayerGroup, faBroom, faClone);

const toast = namespace('toast');
const project = namespace('project');

@Component({
  components: { RunStatus, EmptyListPlaceholder, DateTime, SearchInput, EnvironmentIcon, SortInput },
})
export default class EnvironmentListComponent extends Vue {
  private pollInterval: number | undefined;
  environmentList: EnvironmentList | null = null;
  filter: QueryEnvironmentType = 'all';
  private q = '';
  sort: SortEnvironments = 'description';

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  @project.Getter
  currentItem!: Project | null;

  @Watch('currentItem._id')
  onCurrentItemChanged() {
    this.load();
  }

  onFilter(filter: QueryEnvironmentType) {
    this.filter = filter;
    this.load();
  }

  onSearchInput(q: string) {
    this.q = q;
    this.load();
  }

  onSortInput(sort: SortEnvironments) {
    this.sort = sort;
    this.load();
  }

  private async load() {
    if (this.currentItem) {
      this.environmentList = await api.listEnvironments({
        $type: this.filter,
        q: this.q,
        project: this.currentItem._id,
        sort: this.sort,
      });
    }
  }

  async mounted(): Promise<void> {
    await this.load();
    this.pollInterval = setInterval(() => void this.load(), 10000);
  }

  beforeDestroy(): void {
    clearInterval(this.pollInterval);
  }

  async clearCache(environment: Environment): Promise<void> {
    await api.invalidateEnvironment(environment._id);
    this.setToast({ message: `Environment “${environment.description}” cache was cleared.` });
  }

  async remove(environment: Environment): Promise<void> {
    const reallyDelete = await this.$bvModal.msgBoxConfirm(`Really delete environment “${environment.description}”?`, {
      okVariant: 'danger',
      okTitle: 'Delete',
      cancelVariant: 'outline-dark',
      cancelTitle: 'Cancel',
    });
    if (reallyDelete) {
      await api.deleteEnvironment(environment._id);
      this.load();
      this.setToast({ message: `Environment “${environment.description}” was deleted.` });
    }
  }

  get cannotCreate(): boolean {
    if (!this.currentItem) {
      return true;
    }
    return !ability.can('create', { kind: 'environment', project: this.currentItem._id });
  }

  get filterOptions(): { key: QueryEnvironmentType; class: string; label: string; count: number }[] {
    const environmentList = this.environmentList;
    if (!environmentList) {
      return [];
    }
    return (['all', 'knime', 'shell'] as const).map((property) => ({
      key: property,
      class: this.filter === property ? 'active' : '',
      label: property === 'knime' ? 'KNIME' : capitalize(property),
      count: environmentList[`${property}Count` as keyof EnvironmentList] as number,
    }));
  }

  get sortOptions(): { key: SortEnvironments; label: string }[] {
    return [
      { key: 'description', label: 'Description' },
      { key: 'updatedAt', label: 'Last Modified ↓' },
      { key: '-updatedAt', label: 'Last Modified ↑' },
    ];
  }
}
