/**
 * nodepit-runner-api
 * 1.15.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from 'oazapfts/lib/runtime';
import * as QS from 'oazapfts/lib/runtime/query';

export const defaults: Oazapfts.RequestOpts = {
  baseUrl: '/',
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {
  server1: '/',
};
export type EmailAndPasswordParams = {
  email: string;
  password: string;
};
export type TokenResult = {
  token: string;
};
export type ObjectId = string;
export type UserRole = 'user' | 'admin';
export type Action =
  | 'view'
  | 'list'
  | 'get'
  | 'create'
  | 'update'
  | 'delete'
  | 'invalidate'
  | 'cleanup'
  | 'impersonate';
export type StringSubject =
  | 'environment'
  | 'knimeEnvironment'
  | 'shellEnvironment'
  | 'file'
  | 'run'
  | 'executor'
  | 'schedule'
  | 'knimeSchedule'
  | 'shellSchedule'
  | 'user'
  | 'dashboard'
  | 'settings'
  | 'project'
  | 'stats';
export type AppAbilityRule = {
  action: Action | Action[];
  fields?: string | string[];
  subject: StringSubject | any;
};
export type UserWithAbilities = {
  _id: ObjectId;
  kind: 'user';
  email: string;
  role?: UserRole;
  confirmedAt?: string;
  lastLoginAt?: string;
  createdAt?: string;
  updatedAt?: string;
  deletable?: boolean;
  abilityRules: AppAbilityRule[];
};
export type User = {
  _id: ObjectId;
  kind: 'user';
  email: string;
  role?: UserRole;
  confirmedAt?: string;
  lastLoginAt?: string;
  createdAt?: string;
  updatedAt?: string;
  deletable?: boolean;
};
export type CreateOrUpdateUserParams = {
  email: string;
  role: UserRole;
};
export type SortUsers = 'email' | '-email' | 'createdAt' | '-createdAt' | 'lastLoginAt' | '-lastLoginAt';
export type UserList = {
  users: User[];
  allCount: number;
};
export type RecoverParams = {
  email: string;
};
export type ResetParams = {
  resetId: string;
  token: string;
};
export type ResetWithPasswordParams = ResetParams & {
  password: string;
};
export type Stats = {
  executorCount?: number;
  projectCount?: number;
  environmentCount?: number;
  fileCount?: number;
  scheduleCount?: number;
  runCount?: number;
  userCount?: number;
};
export type Settings = {
  licenseKey: string | null;
  licenseDescription: string | null;
  licenseIssued: string | null;
  licenseExpires: string | null;
  smtpUrl: string | null;
  timeZone: string | null;
  senderEmail: string | null;
  version: string;
  cancelPendingRunAfterMinutes: number | null;
  cancelRunningRunAfterMinutes: number | null;
};
export type UpdateSettingsParams = {
  licenseKey?: string | null;
  smtpUrl?: string | null;
  timeZone?: string | null;
  senderEmail?: string | null;
  cancelPendingRunAfterMinutes?: number | null;
  cancelRunningRunAfterMinutes?: number | null;
};
export type SendTestEmailParams = {
  senderEmail: string;
  smtpUrl: string;
};
export type SendTestEmailResult = {
  success: boolean;
  message?: string;
};
export type SettingsCheck = {
  noUser: boolean;
  emailSettingsValid: boolean;
  licenseValid: boolean | null;
  licenseExpires: string | null;
  cancelPendingRunAfterMinutes: number | null;
  cancelRunningRunAfterMinutes: number | null;
};
export type CronCheckResult = {
  timeout: number;
  nextRun: string;
  explanation: string;
};
export type CronCheckError = {
  error: true;
  message: string;
};
export type QueryEnvironmentType = 'shell' | 'knime' | 'all';
export type SortSchedules = 'description' | '-description' | 'updatedAt' | '-updatedAt';
export type EnvironmentTypeKnime = 'knime';
export type FileType = 'artifact' | 'upload';
export type File = {
  _id: ObjectId;
  kind: 'file';
  originalName: string;
  mimeType: string;
  size: number;
  path: string;
  createdAt: string;
  createdBy: ObjectId;
  project: ObjectId;
  download: string;
  deletable: boolean;
  type: FileType;
};
export type KnimeEnvironment = {
  _id: ObjectId;
  kind: 'environment';
  description: string;
  type: EnvironmentTypeKnime;
  deletable: boolean;
  latestRun: Run | null;
  token: string;
  additionalDockerInstructions: string | null;
  additionalFiles: File[];
  createdAt: string;
  updatedAt: string;
  createdBy: ObjectId;
  updatedBy: ObjectId;
  project: ObjectId;
  tags: string[];
  knimeVersion: string;
  repositories: {
    url: string;
  }[];
  ius: {
    identifier: string;
  }[];
  epfFile: File | null;
  epfRows: {
    value: string;
    key: string;
  }[];
  vmargs: string | null;
};
export type EnvironmentTypeShell = 'shell';
export type ShellEnvironment = {
  _id: ObjectId;
  kind: 'environment';
  description: string;
  type: EnvironmentTypeShell;
  deletable: boolean;
  latestRun: Run | null;
  token: string;
  additionalDockerInstructions: string | null;
  additionalFiles: File[];
  createdAt: string;
  updatedAt: string;
  createdBy: ObjectId;
  updatedBy: ObjectId;
  project: ObjectId;
  tags: string[];
};
export type Environment = KnimeEnvironment | ShellEnvironment;
export type RunStatus = 'pending' | 'running' | 'canceling' | 'passed' | 'canceled' | 'failed';
export type RunSource = 'api' | 'schedule';
export type ExecutorStatus = 'ready' | 'executing' | 'offline';
export type Executor = {
  _id: ObjectId;
  kind: 'executor';
  description: string;
  tags: string[];
  version: string;
  deletable: boolean;
  status: ExecutorStatus;
  createdAt: string;
  updatedAt: string;
  paused: boolean;
  lastSeenAt: string | null;
  runCount: number;
};
export type Run = {
  _id: ObjectId;
  kind: 'run';
  environment: Environment;
  schedule: Schedule;
  description: string;
  active: boolean;
  status: RunStatus;
  cancelable: boolean;
  deletable: boolean;
  retryable: boolean;
  artifact: File | null;
  createdAt: string;
  updatedAt: string;
  createdBy: User;
  updatedBy: User;
  startedAt: string | null;
  finishedAt: string | null;
  project: ObjectId;
  source: RunSource;
  tags: string[];
  executor: Executor | null;
  estimatedRunningDurationInSeconds: number | null;
};
export type KnimeScheduleSettingsFlowVariable = {
  name: string;
  type: 'String' | 'int' | 'double';
  value: string;
};
export type KnimeScheduleSettingsCredential = {
  name: string;
  login: string;
  password: string;
};
export type KnimeSchedule = {
  _id: ObjectId;
  kind: 'schedule';
  environment: ObjectId;
  type: EnvironmentTypeKnime;
  description: string;
  cron: string;
  active: boolean;
  cleanupRunAfterMinutes: number | null;
  notifyOnRunCanceled: boolean;
  notifyOnRunFailed: boolean;
  notifyOnRunPassed: boolean;
  deletable: boolean;
  runnable: boolean;
  latestRun: Run | null;
  nextRun: string | null;
  additionalFiles: File[];
  createdAt: string;
  updatedAt: string;
  createdBy: ObjectId;
  updatedBy: ObjectId;
  project: ObjectId;
  tags: string[];
  cancelRunningRunAfterMinutes: number | null;
  cancelRunningRunAfterMinutesEnabled: boolean;
  workflow: File;
  flowVariables: KnimeScheduleSettingsFlowVariable[];
  credentials: KnimeScheduleSettingsCredential[];
  xvfb: boolean;
};
export type ShellSchedule = {
  _id: ObjectId;
  kind: 'schedule';
  environment: ObjectId;
  type: EnvironmentTypeShell;
  description: string;
  cron: string;
  active: boolean;
  cleanupRunAfterMinutes: number | null;
  notifyOnRunCanceled: boolean;
  notifyOnRunFailed: boolean;
  notifyOnRunPassed: boolean;
  deletable: boolean;
  runnable: boolean;
  latestRun: Run | null;
  nextRun: string | null;
  additionalFiles: File[];
  createdAt: string;
  updatedAt: string;
  createdBy: ObjectId;
  updatedBy: ObjectId;
  project: ObjectId;
  tags: string[];
  cancelRunningRunAfterMinutes: number | null;
  cancelRunningRunAfterMinutesEnabled: boolean;
  script: File;
};
export type Schedule = KnimeSchedule | ShellSchedule;
export type ScheduleListCount = {
  allCount: number;
  knimeCount: number;
  shellCount: number;
};
export type ScheduleList = {
  schedules: Schedule[];
} & ScheduleListCount;
export type ObjectOrObjectId =
  | ObjectId
  | {
      _id: ObjectId;
    };
export type UpdateKnimeScheduleParams = {
  environment: ObjectOrObjectId;
  description: string;
  cron: string;
  active: boolean;
  cleanupRunAfterMinutes?: number | null;
  notifyOnRunCanceled?: boolean;
  notifyOnRunFailed?: boolean;
  notifyOnRunPassed?: boolean;
  additionalFiles?: ObjectOrObjectId[] | null;
  tags?: string[] | null;
  cancelRunningRunAfterMinutes?: number | null;
  cancelRunningRunAfterMinutesEnabled?: boolean;
  workflow: ObjectOrObjectId;
  flowVariables?: KnimeScheduleSettingsFlowVariable[] | null;
  credentials?: KnimeScheduleSettingsCredential[] | null;
  xvfb?: boolean;
};
export type UpdateShellScheduleParams = {
  environment: ObjectOrObjectId;
  description: string;
  cron: string;
  active: boolean;
  cleanupRunAfterMinutes?: number | null;
  notifyOnRunCanceled?: boolean;
  notifyOnRunFailed?: boolean;
  notifyOnRunPassed?: boolean;
  additionalFiles?: ObjectOrObjectId[] | null;
  tags?: string[] | null;
  cancelRunningRunAfterMinutes?: number | null;
  cancelRunningRunAfterMinutesEnabled?: boolean;
  script: ObjectOrObjectId;
};
export type UpdateScheduleParams = UpdateKnimeScheduleParams | UpdateShellScheduleParams;
export type CreateScheduleParams = UpdateScheduleParams & {
  project: ObjectOrObjectId;
};
export type QueryRunStatus = 'pending' | 'running' | 'canceling' | 'passed' | 'canceled' | 'failed' | 'all';
export type SortRuns = 'status' | '-status' | 'description' | '-description' | 'startedAt' | '-startedAt';
export type RunList = {
  allCount: number;
  pendingCount: number;
  runningCount: number;
  cancelingCount: number;
  passedCount: number;
  canceledCount: number;
  failedCount: number;
  runs: Run[];
  cursorNext: string | null;
};
export type GetNextRunParams = {
  executorId: string;
};
export type UpdateRunParams = {
  status: RunStatus;
  artifact?: ObjectOrObjectId | null;
};
export type Log = {
  chunks: number;
  logs: string[];
};
export type UpdateRunLogParams = {
  log: string[];
};
export type CleanupRunsResult = {
  numSchedulesProcessed: number;
  numRunsDeleted: number;
};
export type ProjectRole = 'owner' | 'maintainer' | 'operator' | 'reader';
export type ProjectMember = {
  user: ObjectId;
  role: ProjectRole;
};
export type Project = {
  _id: ObjectId;
  kind: 'project';
  description: string;
  deletable: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: ObjectId;
  updatedBy: ObjectId;
  members: ProjectMember[];
  environmentCount: number;
  fileCount: number;
  scheduleCount: number;
  runCount: number;
  userCount: number;
};
export type ProjectList = {
  projects: Project[];
  allCount: number;
};
export type CreateOrUpdateProjectMember = {
  user: ObjectOrObjectId;
  role: ProjectRole;
};
export type CreateOrUpdateProjectParams = {
  description: string;
  members: CreateOrUpdateProjectMember[];
};
export type QueryFileType = 'artifact' | 'upload' | 'all';
export type SortFiles = 'originalName' | 'size' | '-size' | 'createdAt' | '-createdAt';
export type FileListCounts = {
  allCount: number;
  uploadCount: number;
  artifactCount: number;
};
export type FileList = {
  files: File[];
} & FileListCounts;
export type SortExecutors = 'description' | '-description' | 'lastSeenAt' | '-lastSeenAt' | 'runCount' | '-runCount';
export type ExecutorList = {
  executors: Executor[];
  allCount: number;
};
export type CreateOrUpdateExecutorParams = {
  description: string;
  tags: string[] | null;
  version: string;
  paused?: boolean;
};
export type CleanupExecutorsResult = {
  numExecutorsDeleted: number;
};
export type SortEnvironments = 'description' | '-description' | 'updatedAt' | '-updatedAt';
export type EnvironmentListCount = {
  allCount: number;
  knimeCount: number;
  shellCount: number;
};
export type EnvironmentList = {
  environments: Environment[];
} & EnvironmentListCount;
export type UpdateKnimeEnvironment = {
  description: string;
  additionalDockerInstructions?: string | null;
  additionalFiles?: ObjectOrObjectId[] | null;
  tags?: string[] | null;
  knimeVersion: string;
  repositories?:
    | {
        url: string;
      }[]
    | null;
  ius?:
    | {
        identifier: string;
      }[]
    | null;
  epfFile?: ObjectOrObjectId | null;
  epfRows?:
    | {
        value: string;
        key: string;
      }[]
    | null;
  vmargs?: string | null;
};
export type CreateKnimeEnvironment = UpdateKnimeEnvironment & {
  project: ObjectId;
  type: EnvironmentTypeKnime;
};
export type UpdateShellEnvironment = {
  description: string;
  additionalDockerInstructions?: string | null;
  additionalFiles?: ObjectOrObjectId[] | null;
  tags?: string[] | null;
};
export type CreateShellEnvironment = UpdateShellEnvironment & {
  project: ObjectId;
  type: EnvironmentTypeShell;
};
export type CreateEnvironmentParams = CreateKnimeEnvironment | CreateShellEnvironment;
export type UpdateEnvironmentParams = UpdateKnimeEnvironment | UpdateShellEnvironment;
/**
 * Register a new user.
 */
export function register(emailAndPasswordParams: EmailAndPasswordParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: TokenResult;
    }>(
      '/user/register',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: emailAndPasswordParams,
      })
    )
  );
}
/**
 * Get information about the current user.
 */
export function getMe(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: UserWithAbilities;
    }>('/user/me', {
      ...opts,
    })
  );
}
/**
 * Get a user by its ID.
 */
export function getUser(userId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: User;
    }>(`/user/${encodeURIComponent(userId)}`, {
      ...opts,
    })
  );
}
/**
 * Delete an existing user by its ID.
 */
export function deleteUser(userId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/user/${encodeURIComponent(userId)}`, {
      ...opts,
      method: 'DELETE',
    })
  );
}
/**
 * Update an existing user by its ID.
 */
export function updateUser(
  userId: ObjectId,
  createOrUpdateUserParams: CreateOrUpdateUserParams,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: User;
    }>(
      `/user/${encodeURIComponent(userId)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: createOrUpdateUserParams,
      })
    )
  );
}
/**
 * Confirm a new user.
 */
export function confirmUser(userId: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: User;
    }>(`/user/${encodeURIComponent(userId)}/confirm`, {
      ...opts,
      method: 'POST',
    })
  );
}
/**
 * Get a list of all users.
 */
export function listUsers(
  {
    q,
    confirmed,
    sort,
  }: {
    q?: string;
    confirmed?: boolean;
    sort?: SortUsers;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: UserList;
    }>(
      `/user${QS.query(
        QS.explode({
          q,
          confirmed,
          sort,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
/**
 * Create a new user.
 */
export function createUser(createOrUpdateUserParams: CreateOrUpdateUserParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: User;
    }>(
      '/user',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: createOrUpdateUserParams,
      })
    )
  );
}
/**
 * Login.
 */
export function login(emailAndPasswordParams: EmailAndPasswordParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: TokenResult;
    }>(
      '/user/login',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: emailAndPasswordParams,
      })
    )
  );
}
/**
 * Request a password reset email.
 */
export function requestPasswordReset(recoverParams: RecoverParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(
      '/user/request-password-reset',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: recoverParams,
      })
    )
  );
}
/**
 * Validate if the reset ID and token are valid.
 */
export function checkPasswordReset(resetParams: ResetParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(
      '/user/check-password-reset',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: resetParams,
      })
    )
  );
}
/**
 * Set a new password.
 */
export function resetPassword(resetWithPasswordParams: ResetWithPasswordParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: TokenResult;
    }>(
      '/user/reset-password',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: resetWithPasswordParams,
      })
    )
  );
}
export function getStats(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Stats;
    }>('/stats', {
      ...opts,
    })
  );
}
export function getSettings(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Settings;
    }>('/settings', {
      ...opts,
    })
  );
}
export function updateSettings(updateSettingsParams: UpdateSettingsParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Settings;
    }>(
      '/settings',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: updateSettingsParams,
      })
    )
  );
}
export function sendTestEmail(sendTestEmailParams: SendTestEmailParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: SendTestEmailResult;
    }>(
      '/settings/test-email',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: sendTestEmailParams,
      })
    )
  );
}
export function checkSettings(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: SettingsCheck;
    }>('/settings/check', {
      ...opts,
    })
  );
}
export function cronCheck(
  {
    expression,
  }: {
    expression?: string;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: CronCheckResult | CronCheckError;
    }>(
      `/schedule/cron-check${QS.query(
        QS.explode({
          expression,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
export function listSchedules(
  {
    $type,
    q,
    project,
    sort,
  }: {
    $type?: QueryEnvironmentType;
    q?: string;
    project?: ObjectId;
    sort?: SortSchedules;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: ScheduleList;
    }>(
      `/schedule${QS.query(
        QS.explode({
          type: $type,
          q,
          project,
          sort,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
export function createSchedule(createScheduleParams: CreateScheduleParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: Schedule;
    }>(
      '/schedule',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: createScheduleParams,
      })
    )
  );
}
export function getSchedule(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Schedule;
    }>(`/schedule/${encodeURIComponent(id)}`, {
      ...opts,
    })
  );
}
export function updateSchedule(id: ObjectId, updateScheduleParams: UpdateScheduleParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Schedule;
    }>(
      `/schedule/${encodeURIComponent(id)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: updateScheduleParams,
      })
    )
  );
}
export function deleteSchedule(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/schedule/${encodeURIComponent(id)}`, {
      ...opts,
      method: 'DELETE',
    })
  );
}
export function runSchedule(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Run;
    }>(`/schedule/${encodeURIComponent(id)}/run`, {
      ...opts,
      method: 'PUT',
    })
  );
}
export function listRuns(
  {
    before,
    after,
    limit,
    status,
    project,
    q,
    sort,
    cursor,
    executor,
  }: {
    before?: string;
    after?: string;
    limit?: number;
    status?: QueryRunStatus;
    project?: ObjectId;
    q?: string;
    sort?: SortRuns;
    cursor?: string;
    executor?: ObjectId;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: RunList;
    }>(
      `/run${QS.query(
        QS.explode({
          before,
          after,
          limit,
          status,
          project,
          q,
          sort,
          cursor,
          executor,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
export function getAndUpdateNextRun(getNextRunParams: GetNextRunParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Run | null;
    }>(
      '/run/next',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: getNextRunParams,
      })
    )
  );
}
export function getRun(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Run;
    }>(`/run/${encodeURIComponent(id)}`, {
      ...opts,
    })
  );
}
export function updateRun(id: ObjectId, updateRunParams: UpdateRunParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Run;
    }>(
      `/run/${encodeURIComponent(id)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: updateRunParams,
      })
    )
  );
}
export function deleteRun(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/run/${encodeURIComponent(id)}`, {
      ...opts,
      method: 'DELETE',
    })
  );
}
export function getLog(
  id: ObjectId,
  {
    skip,
    limit,
  }: {
    skip?: number;
    limit?: number;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Log;
    }>(
      `/run/${encodeURIComponent(id)}/log${QS.query(
        QS.explode({
          skip,
          limit,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
export function updateLog(id: ObjectId, updateRunLogParams: UpdateRunLogParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: RunStatus;
    }>(
      `/run/${encodeURIComponent(id)}/log`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: updateRunLogParams,
      })
    )
  );
}
export function cancelRun(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/run/${encodeURIComponent(id)}/cancel`, {
      ...opts,
      method: 'POST',
    })
  );
}
export function retryRun(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Run;
    }>(`/run/${encodeURIComponent(id)}/retry`, {
      ...opts,
      method: 'POST',
    })
  );
}
export function cleanupRuns(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: CleanupRunsResult;
    }>('/run/cleanup', {
      ...opts,
      method: 'POST',
    })
  );
}
export function listProjects(
  {
    q,
  }: {
    q?: string;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: ProjectList;
    }>(
      `/project${QS.query(
        QS.explode({
          q,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
export function createProject(createOrUpdateProjectParams: CreateOrUpdateProjectParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: Project;
    }>(
      '/project',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: createOrUpdateProjectParams,
      })
    )
  );
}
export function getProject(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Project;
    }>(`/project/${encodeURIComponent(id)}`, {
      ...opts,
    })
  );
}
export function updateProject(
  id: ObjectId,
  createOrUpdateProjectParams: CreateOrUpdateProjectParams,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Project;
    }>(
      `/project/${encodeURIComponent(id)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: createOrUpdateProjectParams,
      })
    )
  );
}
export function deleteProject(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/project/${encodeURIComponent(id)}`, {
      ...opts,
      method: 'DELETE',
    })
  );
}
export function getHealth(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: {
        uptime: number;
        status: 'healthy';
      };
    }>('/health', {
      ...opts,
    })
  );
}
export function listFiles(
  {
    $type,
    q,
    project,
    sort,
  }: {
    $type?: QueryFileType;
    q?: string;
    project?: ObjectId;
    sort?: SortFiles;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: FileList;
    }>(
      `/file${QS.query(
        QS.explode({
          type: $type,
          q,
          project,
          sort,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
export function getFile(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: File;
    }>(`/file/${encodeURIComponent(id)}`, {
      ...opts,
    })
  );
}
export function deleteFile(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/file/${encodeURIComponent(id)}`, {
      ...opts,
      method: 'DELETE',
    })
  );
}
export function listExecutors(
  {
    q,
    sort,
  }: {
    q?: string;
    sort?: SortExecutors;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: ExecutorList;
    }>(
      `/executor${QS.query(
        QS.explode({
          q,
          sort,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
export function createExecutor(
  createOrUpdateExecutorParams: CreateOrUpdateExecutorParams,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: Executor;
    }>(
      '/executor',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: createOrUpdateExecutorParams,
      })
    )
  );
}
export function getExecutor(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Executor;
    }>(`/executor/${encodeURIComponent(id)}`, {
      ...opts,
    })
  );
}
export function updateExecutor(
  id: ObjectId,
  createOrUpdateExecutorParams: CreateOrUpdateExecutorParams,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Executor;
    }>(
      `/executor/${encodeURIComponent(id)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: createOrUpdateExecutorParams,
      })
    )
  );
}
export function deleteExecutor(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/executor/${encodeURIComponent(id)}`, {
      ...opts,
      method: 'DELETE',
    })
  );
}
export function sendHeartbeat(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Executor;
    }>(`/executor/${encodeURIComponent(id)}/heartbeat`, {
      ...opts,
      method: 'PUT',
    })
  );
}
export function cleanupExecutors(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: CleanupExecutorsResult;
    }>('/executor/cleanup', {
      ...opts,
      method: 'POST',
    })
  );
}
export function listEnvironments(
  {
    $type,
    q,
    project,
    sort,
  }: {
    $type?: QueryEnvironmentType;
    q?: string;
    project?: ObjectId;
    sort?: SortEnvironments;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: EnvironmentList;
    }>(
      `/environment${QS.query(
        QS.explode({
          type: $type,
          q,
          project,
          sort,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
export function createEnvironment(createEnvironmentParams: CreateEnvironmentParams, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: Environment;
    }>(
      '/environment',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: createEnvironmentParams,
      })
    )
  );
}
export function getEnvironment(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Environment;
    }>(`/environment/${encodeURIComponent(id)}`, {
      ...opts,
    })
  );
}
export function updateEnvironment(
  id: ObjectId,
  updateEnvironmentParams: UpdateEnvironmentParams,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 200;
      data: Environment;
    }>(
      `/environment/${encodeURIComponent(id)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: updateEnvironmentParams,
      })
    )
  );
}
export function deleteEnvironment(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/environment/${encodeURIComponent(id)}`, {
      ...opts,
      method: 'DELETE',
    })
  );
}
export function invalidateEnvironment(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/environment/${encodeURIComponent(id)}/invalidate`, {
      ...opts,
      method: 'PUT',
    })
  );
}
/**
 * Upload a new file.
 */
export function uploadFile(
  body: {
    file: Blob;
    project?: string;
  },
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: File;
    }>(
      '/file/upload',
      oazapfts.multipart({
        ...opts,
        method: 'POST',
        body,
      })
    )
  );
}
/**
 * Get the content of a file.
 */
export function downloadFile(
  fileId: ObjectId,
  {
    attachment,
  }: {
    attachment?: boolean;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchBlob<{
      status: 200;
      data: Blob;
    }>(
      `/file/${encodeURIComponent(fileId)}/download${QS.query(
        QS.explode({
          attachment,
        })
      )}`,
      {
        ...opts,
      }
    )
  );
}
export function updateArtifact(
  id: ObjectId,
  body: {
    file: Blob;
  },
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<{
      status: 201;
      data: File;
    }>(
      `/run/${encodeURIComponent(id)}/artifact`,
      oazapfts.multipart({
        ...opts,
        method: 'POST',
        body,
      })
    )
  );
}
export function downloadLog(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/run/${encodeURIComponent(id)}/log/download`, {
      ...opts,
    })
  );
}
/**
 * Get the run worker.
 */
export function getWorker(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchBlob<{
      status: 200;
      data: Blob;
    }>(`/run/${encodeURIComponent(id)}/worker`, {
      ...opts,
    })
  );
}
/**
 * Get the run data.
 */
export function getData(id: ObjectId, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchBlob<{
      status: 200;
      data: Blob;
    }>(`/run/${encodeURIComponent(id)}/data`, {
      ...opts,
    })
  );
}
