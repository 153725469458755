










import { playPauseCircle } from '@/icon-pack';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faPauseCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { Component, Prop, Vue } from 'vue-property-decorator';

const iconMapping: { [k: string]: IconDefinition } = {
  ready: faCircle,
  executing: faPlayCircle,
  offline: faCircle,
};

const titleMapping: { [k: string]: string } = {
  ready: 'Ready for executing new runs.',
  executing: 'Executing a run.',
  offline: 'Offline.',
};

@Component
export default class ExecutorStatusIcon extends Vue {
  /** The status type. */
  @Prop() readonly status: string | undefined;

  /** Whether the executor is paused. */
  @Prop() readonly paused: boolean | undefined;

  get icon(): IconDefinition | undefined {
    if (this.paused) {
      return this.status === 'executing' ? playPauseCircle : faPauseCircle;
    }
    return this.status ? iconMapping[this.status] : undefined;
  }

  get title(): string | undefined {
    if (this.paused) {
      return this.status === 'executing'
        ? 'Executing a run. The executor is paused and will not execute new runs.'
        : 'Paused. The executor will not execute new runs.';
    }
    return this.status ? titleMapping[this.status] : undefined;
  }
}
